<template>
    <div class="video-container">
        <video ref="video" class="video" autoplay playsinline muted></video>
        <div class="address">
            <p v-if="address"><strong>{{$t('your_address')}}</strong> <br>{{ address }}</p>
            <p v-else>{{$t('uploading_geodata')}}</p>
        </div>
        <v-btn icon color="#ffffff" class="capture-btn"  @click="capturePhoto">
            <v-icon size="100">icon icon-taking-pictures-circle</v-icon>
        </v-btn>
        <v-btn v-if="false" icon color="primary" class="switch-camera-btn" @click="switchCamera">
            <v-icon size="50">mdi-camera-flip-outline</v-icon>
        </v-btn>
        <v-btn icon color="#ffffff" class="close-camera-btn"  @click="closeCamera">
            <v-icon size="30">icon icon-close</v-icon>
        </v-btn>
        <canvas ref="canvas" style="display: none;"></canvas>

        <div v-if="false">
            <p><strong>Ваши координаты:</strong></p>
            <p>Широта: {{ geoData.latitude }}</p>
            <p>Долгота: {{ geoData.longitude }}</p>
            <p v-if="address"><strong>Ваш адрес:</strong> {{ address }}</p>
        </div>
        <div v-if="geoData && showMap">
            <yandex-map
                v-if="false"
                :coords="[geoData.latitude, geoData.longitude]"
                zoom="16"
                style="width: 100%; height: 400px;"
            >

            </yandex-map>
        </div>

        <v-snackbar v-model="snackbar">
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    {{ $t('close') }}
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>

import {loadYmap, yandexMap} from 'vue-yandex-maps'


export default {
    name: "CameraCapture",
    props: {
        dialog: Boolean,  // Пропс для отслеживания состояния диалога
    },
    components: {
        yandexMap
    },
    computed: {
        mapSettings() {
            this.$yandex.lang = 'ru_RU'
            return this.$yandex
        },
    },
    data() {
        return {
            photo: null,   // Снимок
            error: null,   // Ошибка при получении доступа к камере
            stream: null,  // Поток камеры
            videoDeviceIds: [], // Список доступных камер
            currentDeviceIndex: 0, // Индекс текущей камеры

            geoData: null,
            address: '',
            showMap: false,

            snackbar: false,
            text: null,
        };
    },
    mounted() {
        this.startCamera();
        this.ymapInit();
    },
    beforeDestroy() {
        // Останавливаем камеру при уничтожении компонента
        this.stopCamera();
    },
    watch: {
        // Слежение за изменением состояния пропса `dialog`
        dialog(newValue) {
            if (newValue) {
                // Если диалог открыт, запускаем камеру
                this.startCamera();
            } else {
                // Если диалог закрыт, останавливаем камеру
                this.stopCamera();
            }
        }
    },
    methods: {
        async initCamera() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.videoDeviceIds = devices
                    .filter((device) => device.kind === 'videoinput')
                    .map((device) => device.deviceId);

                // Используем первую камеру
                if (this.videoDeviceIds.length > 0) {
                    this.startCamera(this.videoDeviceIds[this.currentDeviceIndex]);
                }
            } catch (error) {
                this.snackbar = true;
                this.text = this.$t('camera_initialization_error');
                console.error('Ошибка инициализации камеры:', error);
            }
        },
        async startCamera(deviceId) {
            try {
                if (this.stream) {
                    this.stopCamera();
                }
                this.stream = await navigator.mediaDevices.getUserMedia({
                    video: {deviceId},
                });
                this.$refs.video.srcObject = this.stream;
            } catch (error) {
                this.snackbar = true;
                this.text = this.$t('camera_launch_error');
                console.error('Ошибка запуска камеры:', error);
            }
        },
        // Переключение между камерами
        switchCamera() {
            if (this.videoDeviceIds.length > 1) {
                this.currentDeviceIndex =
                    (this.currentDeviceIndex + 1) % this.videoDeviceIds.length;
                this.startCamera(this.videoDeviceIds[this.currentDeviceIndex]);
            } else {
                this.snackbar = true;
                this.text = this.$t('only_one_camera_is_available');
            }
        },
        capturePhoto() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;

            // Устанавливаем размер канваса равным размеру видео
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Рисуем изображение с видео на канвас
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

            // Получаем изображение как data URL
            const dataUrl = canvas.toDataURL('image/png');

            // Устанавливаем фото в состояние
            this.photo = dataUrl;

            // Отправляем фото в родительский компонент
            this.$emit('photo-taken', {'photo': this.photo, 'geo_data': this.geoData, 'address': this.address});

            // Закрываем камеру после снятия фото
            this.stopCamera();
        },
        stopCamera() {
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
            }
        },
        closeCamera(){
            this.startCamera();
            this.$emit('photo-close');

        },


        async ymapInit() {
            if (window.ymaps) {
                this.showMap = false;

                if (this.$refs.map && this.$refs.map.myMap) {
                    this.$refs.map.myMap?.destroy();
                }

                const mapElement = document.getElementById("vue-yandex-maps");
                if (mapElement) {
                    mapElement.remove();
                }
            }

            await loadYmap({...this.mapSettings, debug: true});

            if (window.ymaps) {
                window.ymaps.ready(() => {
                    this.showMap = true;
                    this.getGeoData()

                });
            }
        },
        getGeoData() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        this.geoData = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        };
                        this.showMap = true;

                        // Получаем адрес через геокодер Яндекс.Карт
                        await this.getAddress();
                    },
                    (error) => {
                        console.error("Ошибка получения геопозиции:", error.message);
                        this.snackbar = true;
                        this.text = this.$t('could_not_get_location');
                    }
                );
            } else {
                this.snackbar = true;
                this.text = this.$t('your_browser_does_not_support_geolocation');
            }
        },
        async getAddress() {
            const ymaps = await window.ymaps;

            ymaps.geocode([this.geoData.latitude, this.geoData.longitude]).then(
                (res) => {
                    const firstGeoObject = res.geoObjects.get(0);
                    this.address = firstGeoObject.getAddressLine();
                },
                (error) => {
                    console.error("Ошибка получения адреса:", error);
                    this.address = this.$t('could_not_determine_address');
                    this.snackbar = true;
                    this.text = this.$t('could_not_determine_address');
                }
            );
        }


    },
};
</script>

<style scoped>
.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.capture-btn {
    position: absolute;
    bottom: 100px;
}

.switch-camera-btn {
    position: absolute;
    bottom: 60px;
    right: 10px;
}

.close-camera-btn {
    position: absolute;
    top: 20px;
    right: 10px;
}
.address{
    position: absolute;
    top: 20px;
    font-size:24px;
    color:#ffffff;
    line-height: 30px
}
</style>
