<script>
import {mapGetters} from "vuex";

export default {
    name: "Search",

    props: {
        searchText: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        ...mapGetters(['lang']),
        filterSearchItems() {
            if (this.search_type === '') {
                return this.searchItems; // Возвращает весь массив, если тип не передан
            } else {
                return this.searchItems.filter(item => item.type === this.search_type); // Фильтрует массив по указанному типу
            }
        }
    },

    data() {
        return {
            search_type: 'all',
            tabs: 0,
            loading: false,
            searchItems: [],
            searchTextInner: '',

            header_tabs: [
                {
                    name: 'all_results',
                    slug: '',
                    count: 0,
                },
                {
                    name: 'uveds',
                    slug: 'client',
                    count: 0,
                },
                {
                    name: 'tasks',
                    slug: 'task',
                    count: 0,
                },
                {
                    name: 'deals',
                    slug: 'deal',
                    count: 0,
                },
                // {
                //     name: 'files',
                //     slug: 'file',
                //     count: 0,
                // }
            ],
        }
    },

    watch: {
        searchItems: {
            handler: function (val) {
                this.header_tabs.forEach(tab => {
                    tab.count = this.countItemsByType(tab.slug);
                });
                this.header_tabs[0].count = this.searchItems.length;
            },
            immediate: true
        },

        searchText: {
            handler: function (val) {
                this.search(val);
            },
            immediate: true
        }
    },

    methods: {
        async search(val) {
            if(val === '') {
                this.searchItems = [];
                return;
            }

            this.loading = true;

            let params = {}
            params.search = val
            params.language = this.lang

            await this.$http
                .get("admin/search", {
                    params: params,
                })
                .then(res => {
                    this.searchItems = res.body.data
                })
                .catch(err => {
                    this.searchItems = []
                })
                .finally(end => {
                    this.loading = false;
                    this.searchTextInner = val;
                })
        },

        countItemsByType(type) {
            return this.searchItems.filter(item => item.type === type).length;
        },

        onTabChange(slug) {
            this.search_type = this.header_tabs[slug].slug;
        },

        onItemClick(item) {
            this.$emit('itemClick', item);
        }
    }
}
</script>

<template>
    <v-card flat class="search-result">
        <template
            v-if="searchItems.length > 0"
        >
            <v-tabs
                grow
                @change="onTabChange"
                class="search-result__sections"
                hide-slider
            >
                <v-tab
                    v-for="(item, i) in header_tabs"
                    :key="i"
                    style="text-transform:none !important;"
                >
                    {{ $t(item.name) }} <span v-if="item.count > 0" class="pl-1" style="color: #B3B3B3">{{ item.count }}</span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" class="search-result__content">
                <v-tab-item>
                    <v-card flat>
                        <v-list three-line>
                            <template v-for="(item, index) in filterSearchItems">
                                <v-list-item :key="index + item.title" style="min-height: auto !important;" class="search-result-item">
                                    <v-list-item-icon class="mx-0 pr-3 mt-3">
                                        <v-icon color="grey lighten-1" size="20" v-if="item.type === 'client'">
                                            mdi-account-multiple-outline
                                        </v-icon>
                                        <v-icon color="grey lighten-1" size="20" v-else-if="item.type === 'task'">
                                            mdi-calendar-check
                                        </v-icon>
                                        <v-icon color="grey lighten-1" size="20" v-else-if="item.type === 'deal'">
                                            mdi-briefcase-variant-outline
                                        </v-icon>
                                        <v-icon color="grey lighten-1" size="18" v-else-if="item.type === 'file'">
                                            icon-file-text
                                        </v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content class="search-result-item__content">
                                        <div class="pb-1">
                                            <v-btn v-if="item.type === 'client'" text plain :to="'/uved/' + item.uuid + '/show'" class="cursor-pointer text--primary button_link search_link" @click="onItemClick">
                                                <span class="text--primary">{{ $t('uved.self') }}: </span>
                                                {{ item.title }}
                                            </v-btn>
                                            <v-btn v-else-if="item.type === 'task'" text plain :to="'/task/' + item.uuid + '/show'" class="cursor-pointer text--primary button_link search_link" @click="onItemClick">
                                                <span class="text--primary">{{ $t('task.self') }}: </span>
                                                {{ item.title }}
                                            </v-btn>
                                            <v-btn v-else-if="item.type === 'deal'" text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer text--primary button_link search_link" @click="onItemClick">
                                                <span class="text--primary">{{ $t('deal.self') }}: </span>
                                                {{ item.title }}
                                            </v-btn>
                                            <v-btn v-else-if="item.type === 'deal_documents'" text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer text--primary button_link search_link" @click="onItemClick">
                                                <span class="text--primary">{{ $t('deal.self') }}: </span>
                                                {{ item.title }}
                                            </v-btn>
                                            <v-btn v-else-if="item.type === 'task_documents'" text plain :to="'/task/' + item.uuid + '/show'" class="cursor-pointer text--primary button_link search_link" @click="onItemClick">
                                                <span class="text--primary">{{ $t('task.self') }}: </span>
                                                {{ item.title }}
                                            </v-btn>
                                            <v-btn v-else-if="item.type === 'file'" text plain :to="'/file/' + item.uuid + '/show'" class="cursor-pointer text--primary button_link search_link" @click="onItemClick">
                                                <span class="text--primary">{{ $t('file') }}: </span>
                                                {{ item.title }}
                                            </v-btn>
                                        </div>
                                        <template v-for="(search, key) in item.search">
                                            <div :key="key + item.type" class="pb-1 search-result-item__desc">
                                                <span v-if="search.type === 'title'">{{ $t('name') }}: </span>
                                                <span v-else-if="search.type === 'description'">{{ $t('description') }}: </span>
                                                <span v-else-if="search.type === 'file'">{{ $t('files') }}: </span>
                                                <span v-else-if="search.type === 'number'">{{ item.type === 'task' ? $t('number_task') : $t('number_deal') }}: </span>
                                                <span v-else-if="search.type === 'email'">{{ $t('email') }}: </span>
                                                <span v-else-if="search.type === 'phone'">{{ $t('phone') }}: </span>
                                                <span v-else-if="search.type === 'file'">{{ $t('file') }}: </span>
                                                <span v-html="search.text"></span>
                                            </div>
                                        </template>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </template>
        <template v-else>
            <v-card-text class="text-center">
                <v-icon size="100" color="grey lighten-1">mdi-magnify-close</v-icon>
                <p class="text--primary">
                    <template v-if="searchTextInner.length > 0">
                        {{ $t('nothing_found_by', {'search': searchTextInner}) }}
                    </template>
                    <template v-else>
                        {{ $t('empty_search_request') }}
                    </template>
                </p>
            </v-card-text>
        </template>
        <v-overlay
            absolute
            :value="loading"
            z-index="10"
            color="grey lighten-3"
        >
            <v-progress-circular
                color="grey darken-1"
                indeterminate
                size="32"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<style lang="scss">
.search-bar {
    position: relative;
    width: 100%;
}

.search-bar__result {
    top: var(--header-height-mobile);
    z-index: 100;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        top: var(--header-height);
    }
}

.theme--light {
    .v-input {
        &.search {
            .v-label,
            .v-icon,
            input {
                //color: #fff;
            }
        }
    }
}

.search-result {
    margin: $grid-gutter / 2;
    overflow: hidden;

    .v-tabs {
        .v-tab {
            letter-spacing: 0 !important;
            font-size: $font-size-root;
        }

        .v-slide-group--is-overflowing.v-tabs-bar--is-mobile {
            .v-slide-group__next,
            .v-slide-group__prev {
                display: none !important;
            }
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        margin: 0;
    }
}

.search-result__sections {
    &.v-tabs > .v-tabs-bar {
        background-color: var(--body);
    }
}

.search-result__content {
    overflow-y: auto;
}

/* search-result-item */
.search-result-item {
    padding: 0;
    border-bottom: 1px solid var(--border-color);
    margin-left: $grid-gutter;
    margin-right: $grid-gutter;

    &:last-child {
        border-bottom: none;
    }
}

.search-result-item__desc {
    font-size: $font-size-small-mobile;
    color: var(--text-color-light);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        font-size: $font-size-small;
    }
}


</style>
